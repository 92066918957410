<template>
    <div class="contact">
      <contact></contact>
    </div>
  </template>
  
  <script>
  import Contact from "@/components/contact/Contact"
  
  export default {
    name: "Index",
    components: {
      Contact
    }
  }
  </script>
  
  <style lang="scss">
  .contact {
    color: #404040;
  }
  </style>
